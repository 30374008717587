var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table table-bordered"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("#")]),_c('th',{on:{"click":function($event){return _vm.toggleSorting('lastname')}}},[_vm._v(" Nachname "),(_vm.sorting['lastname'])?_c('i',{staticClass:"fa fa-fw",class:{
            'fa-caret-up': _vm.sorting['lastname'] === 'asc',
            'fa-caret-down': _vm.sorting['lastname'] === 'desc',
          }}):_vm._e()]),_c('th',{on:{"click":function($event){return _vm.toggleSorting('firstname')}}},[_vm._v(" Vorname "),(_vm.sorting['firstname'])?_c('i',{staticClass:"fa fa-fw",class:{
            'fa-caret-up': _vm.sorting['firstname'] === 'asc',
            'fa-caret-down': _vm.sorting['firstname'] === 'desc',
          }}):_vm._e()]),_c('th',[_vm._v("E-Mail")]),_c('th',[_vm._v("Gesellschaften")]),_c('th',{on:{"click":function($event){return _vm.toggleSorting('permanentEmployment')}}},[_vm._v(" Festanstellung "),(_vm.sorting['permanentEmployment'])?_c('i',{staticClass:"fa fa-fw",class:{
            'fa-caret-up': _vm.sorting['permanentEmployment'] === 'asc',
            'fa-caret-down': _vm.sorting['permanentEmployment'] === 'desc',
          }}):_vm._e()]),_c('th',{staticClass:"text-center"},[_vm._v("Zeiterfassung")]),_c('th',{staticClass:"text-center",on:{"click":function($event){return _vm.toggleSorting('valid')}}},[_vm._v(" Datensatz "),(_vm.sorting['valid'])?_c('i',{staticClass:"fa fa-fw",class:{
            'fa-caret-up': _vm.sorting['valid'] === 'asc',
            'fa-caret-down': _vm.sorting['valid'] === 'desc',
          }}):_vm._e()]),_c('th',{staticClass:"text-center"},[_vm._v("Bearbeiten")]),_c('th',{staticClass:"text-center"},[_vm._v("Löschen")])])]),_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"10"}},[_c('router-link',{attrs:{"to":{ name: 'trainer-create' }}},[_vm._v("+ Trainer erstellen")])],1)]),_c('tr',[_c('td',{attrs:{"colspan":"10"}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.search),expression:"search",modifiers:{"trim":true}}],staticClass:"form-control form-control-sm",attrs:{"type":"search","placeholder":"Trainer suchen..."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])]),_vm._l((_vm.sortedTrainers),function(trainer,index){return _c('tr',{key:trainer.id},[_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(trainer.lastname))]),_c('td',[_vm._v(_vm._s(trainer.firstname))]),_c('td',[_c('a',{attrs:{"href":("mailto:" + (trainer.email))}},[_vm._v(_vm._s(trainer.email))])]),_c('td',_vm._l((trainer.creditors),function(creditor,index){return _c('span',{key:index},[_c('span',[_vm._v(_vm._s(creditor.holding.name))]),_c('br')])}),0),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(trainer.permanentEmployment ? 'Ja':'Nein'))]),_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{
            name: 'time-records',
            params: { trainerId: trainer.id },
          }}},[_vm._v("Zeiterfassung")])],1),_c('td',{staticClass:"text-center"},[_c('i',{staticClass:"fa fa-fw text-center",class:_vm.isInvalidTrainer(trainer)
              ? 'fa-warning text-danger'
              : 'fa-check text-success'})]),_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{ name: 'trainer-details', params: { id: trainer.id } }}},[_c('i',{staticClass:"fa fa-edit text-dark"})])],1),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.remove(trainer.id)}}},[_c('i',{staticClass:"fa fa-trash cursor-pointer"})])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }