<template>
  <table class="table table-bordered">
    <thead class="thead-dark">
      <tr>
        <th class="text-center">#</th>
        <th @click="toggleSorting('lastname')">
          Nachname
          <i
            v-if="sorting['lastname']"
            class="fa fa-fw"
            :class="{
              'fa-caret-up': sorting['lastname'] === 'asc',
              'fa-caret-down': sorting['lastname'] === 'desc',
            }"
          ></i>
        </th>
        <th @click="toggleSorting('firstname')">
          Vorname
          <i
            v-if="sorting['firstname']"
            class="fa fa-fw"
            :class="{
              'fa-caret-up': sorting['firstname'] === 'asc',
              'fa-caret-down': sorting['firstname'] === 'desc',
            }"
          ></i>
        </th>
        <th>E-Mail</th>
        <th>Gesellschaften</th>
        <th @click="toggleSorting('permanentEmployment')">
          Festanstellung
          <i
            v-if="sorting['permanentEmployment']"
            class="fa fa-fw"
            :class="{
              'fa-caret-up': sorting['permanentEmployment'] === 'asc',
              'fa-caret-down': sorting['permanentEmployment'] === 'desc',
            }"
          ></i>
        </th>
        <th class="text-center">Zeiterfassung</th>
        <th @click="toggleSorting('valid')" class="text-center">
          Datensatz
          <i
            v-if="sorting['valid']"
            class="fa fa-fw"
            :class="{
              'fa-caret-up': sorting['valid'] === 'asc',
              'fa-caret-down': sorting['valid'] === 'desc',
            }"
          ></i>
        </th>
        <th class="text-center">Bearbeiten</th>
        <th class="text-center">Löschen</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="10">
          <router-link :to="{ name: 'trainer-create' }">+ Trainer erstellen</router-link>
        </td>
      </tr>
      <tr>
        <td colspan="10">
          <input
            type="search"
            class="form-control form-control-sm"
            placeholder="Trainer suchen..."
            v-model.trim="search"
          />
        </td>
      </tr>
      <tr v-for="(trainer, index) in sortedTrainers" :key="trainer.id">
        <th class="text-center">{{ index + 1 }}</th>
        <td>{{ trainer.lastname }}</td>
        <td>{{ trainer.firstname }}</td>
        <td>
          <a :href="`mailto:${trainer.email}`">{{ trainer.email }}</a>
        </td>
        <td>
          <span v-for="(creditor, index) in trainer.creditors" :key="index">
            <span>{{ creditor.holding.name }}</span>
            <br />
          </span>
        </td>
        <td class="text-center">{{trainer.permanentEmployment ? 'Ja':'Nein' }}</td>
        <td class="text-center">
          <router-link
            :to="{
              name: 'time-records',
              params: { trainerId: trainer.id },
            }"
          >Zeiterfassung</router-link>
        </td>
        <td class="text-center">
          <i
            class="fa fa-fw text-center"
            :class="
              isInvalidTrainer(trainer)
                ? 'fa-warning text-danger'
                : 'fa-check text-success'
            "
          ></i>
        </td>
        <td class="text-center">
          <router-link :to="{ name: 'trainer-details', params: { id: trainer.id } }">
            <i class="fa fa-edit text-dark"></i>
          </router-link>
        </td>
        <td class="text-center" @click="remove(trainer.id)">
          <i class="fa fa-trash cursor-pointer"></i>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { firestore } from '../firebase';
import joi from '@hapi/joi';

const trainerSchema = joi.object({
  firstname: joi.string().trim(),
  lastname: joi.string().trim(),
  email: joi.string().trim(),
  hourlyWage: joi.number().min(0),
  vatkey: joi.alternatives().try(joi.number().valid(9), joi.any().valid(null)),
  iban: joi.string().trim(),
  bic: joi.string().trim(),
  creditors: joi
    .array()
    .items(
      joi.object({
        id: joi
          .string()
          .trim()
          .pattern(/^[0-9]+$/)
          .min(4),
        holding: joi.object({
          id: joi.string().trim(),
          name: joi.string().trim(),
        }),
      })
    )
    .min(1),
  displayName: joi.string().trim(),
  permanentEmployment: joi.boolean(),
  id: joi.string().trim(),
});

export default {
  data() {
    return {
      trainers: [],
      loading: false,
      search: '',
      sorting: {
        firstname: null,
        lastname: null,
        valid: null,
        permanentEmployment: null,
      },
    };
  },
  async created() {
    this.loading = true;

    const trainersSnapshot = await firestore
      .collection('trainers')
      .orderBy('lastname')
      .get();

    this.trainers = trainersSnapshot.docs.map((snapshot) => {
      return {
        id: snapshot.id,
        ...snapshot.data(),
      };
    });

    this.loading = false;
  },
  methods: {
    async remove(id) {
      if (
        confirm(
          'Bist du sicher, dass du diesen Trainer löschen möchtest? Dabei gehen auch alle erfassten Zeiten dieses Trainers verloren.'
        )
      ) {
        await firestore.collection('trainers').doc(id).delete();

        this.trainers = this.trainers.filter((trainer) => trainer.id !== id);
      }
    },
    isInvalidTrainer(v) {
      const { error } = trainerSchema.validate(v);

      return error;
    },
    toggleSorting(predicate) {
      Object.keys(this.sorting).forEach((key) => {
        if (key !== predicate) {
          this.sorting[key] = null;
        }
      });

      switch (this.sorting[predicate]) {
        case null: {
          this.sorting[predicate] = 'asc';
          break;
        }
        case 'asc': {
          this.sorting[predicate] = 'desc';
          break;
        }
        case 'desc': {
          this.sorting[predicate] = null;
          break;
        }
      }
    },
  },
  computed: {
    filteredTrainers() {
      return this.trainers.filter((trainer) => {
        return (
          trainer.displayName
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          trainer.email.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    sortedTrainers() {
      let sortKey = '';
      let sortDirection = '';

      Object.keys(this.sorting).forEach((key) => {
        if (this.sorting[key] !== null) {
          sortKey = key;
          sortDirection = this.sorting[key];
        }
      });

      if (sortKey && sortDirection) {
        return [...this.filteredTrainers].sort((a, b) => {
          let aSortValue = a[sortKey];
          let bSortValue = b[sortKey];

          if (sortKey === 'firstname' || sortKey === 'lastname') {
            aSortValue = aSortValue.toLowerCase();
            bSortValue = bSortValue.toLowerCase();

            if (sortDirection === 'asc') {
              if (aSortValue < bSortValue) {
                return -1;
              }

              if (aSortValue > bSortValue) {
                return 1;
              }

              return 0;
            } else {
              if (aSortValue > bSortValue) {
                return -1;
              }

              if (aSortValue < bSortValue) {
                return 1;
              }

              return 0;
            }
          } else if (sortKey === 'permanentEmployment') {
            if (sortDirection === 'asc') {
              return aSortValue === bSortValue ? 0 : aSortValue ? -1 : 1;
            } else {
              return aSortValue === bSortValue ? 0 : bSortValue ? -1 : 1;
            }
          } else {
            if (sortDirection === 'asc') {
              if (this.isInvalidTrainer(a)) {
                return -1;
              }

              if (this.isInvalidTrainer(b)) {
                return 1;
              }

              return 0;
            } else {
              if (this.isInvalidTrainer(b)) {
                return -1;
              }

              if (this.isInvalidTrainer(a)) {
                return 1;
              }

              return 0;
            }
          }
        });
      }

      return this.filteredTrainers;
    },
  },
};
</script>
